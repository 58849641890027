
<template>
  <div class="flex min-h-screen font-sans">
    <div class="flex flex-col items-center justify-start flex-1 px-4 py-12 sm:px-6 lg:flex-none lg:w-[1200px] lg:px-20 xl:px-24 ">
      <div class="w-full lg:w-[800px]">
        <div class="w-24 ">
          <router-link
            to="/"
            class="w-24 h-24"
          >
            <img
              class="w-24 h-24"
              src="@/assets/logo.svg"
              alt="Logo"
            >
          </router-link>
        </div>

        <article
          id="post-4079"
          class="block p-5 m-0 leading-6 text-left text-white "
          itemtype="https://schema.org/CreativeWork"
          itemscope="itemscope"
        >
          <header class="block mb-0 text-left break-words " />
          <!-- .entry-header -->

          <div
            class="clearfix p-0 m-0 text-left break-words align-baseline shadow-none "
            itemprop="text"
          >
            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-3xl font-medium align-baseline border-0 text-primary"
            >
              Terms and Conditions of Use
            </h2>

            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-2xl font-medium align-baseline border-0 text-primary"
            >
              1. Terms
            </h2>

            <p
              class="p-0 mx-0 mt-0 mb-6 text-white align-baseline border-0 "
            >
              By accessing this web site, you are agreeing to be bound by these web site
              Terms and Conditions of Use, all applicable laws and regulations, and
              agree that you are responsible for compliance with any applicable local
              laws. If you do not agree with any of these terms, you are prohibited from
              using or accessing this site. The materials contained in this web site are
              protected by applicable copyright and trade mark law.
            </p>

            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-2xl font-medium align-baseline border-0 text-primary"
            >
              2. Use License
            </h2>

            <ol

              class="p-0 mt-0 mb-4 ml-12 mr-0 text-white align-baseline list-decimal border-0 "
            >
              <li>
                Permission is granted to temporarily download one copy of the materials
                (information or software) on WoW Classic DB’s web site for personal,
                non-commercial transitory viewing only. This is the grant of a license,
                not a transfer of title, and under this license you may not:
                <ol
                  class="p-0 my-0 ml-6 list-decimal border-0 "
                >
                  <li>
                    modify or copy the materials;
                  </li>
                  <li>
                    use the materials for any commercial purpose, or for any public
                    display (commercial or non-commercial);
                  </li>
                  <li>
                    attempt to decompile or reverse engineer any software contained on
                    WoW Classic DB’s web site;
                  </li>
                  <li>
                    remove any copyright or other proprietary notations from the
                    materials; or
                  </li>
                  <li>
                    transfer the materials to another person or “mirror” the materials
                    on any other server.
                  </li>
                </ol>
              </li>
              <li>
                This license shall automatically terminate if you violate any of these
                restrictions and may be terminated by WoW Classic DB at any time. Upon
                terminating your viewing of these materials or upon the termination of
                this license, you must destroy any downloaded materials in your
                possession whether in electronic or printed format.
              </li>
            </ol>

            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-2xl font-medium align-baseline border-0 text-primary"
            >
              3. Disclaimer
            </h2>

            <ol
              type="a"
              class="p-0 mt-0 mb-4 ml-12 mr-0 text-white align-baseline border-0 "
            >
              <li>
                The materials on WoW Classic DB’s web site are provided “as is”.
                WoW Classic DB makes no warranties, expressed or implied, and hereby
                disclaims and negates all other warranties, including without
                limitation, implied warranties or conditions of merchantability, fitness
                for a particular purpose, or non-infringement of intellectual property
                or other violation of rights. Further, WoW Classic DB does not warrant
                or make any representations concerning the accuracy, likely results, or
                reliability of the use of the materials on its Internet web site or
                otherwise relating to such materials or on any sites linked to this
                site.
              </li>
            </ol>

            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-2xl font-medium align-baseline border-0 text-primary"
            >
              4. Limitations
            </h2>

            <p
              class="p-0 mx-0 mt-0 mb-6 text-white align-baseline border-0 "
            >
              In no event shall WoW Classic DB or its suppliers be liable for any
              damages (including, without limitation, damages for loss of data or
              profit, or due to business interruption,) arising out of the use or
              inability to use the materials on WoW Classic DB’s Internet site, even if
              WoW Classic DB or a WoW Classic DB authorized representative has been
              notified orally or in writing of the possibility of such damage. Because
              some jurisdictions do not allow limitations on implied warranties, or
              limitations of liability for consequential or incidental damages, these
              limitations may not apply to you.
            </p>

            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-2xl font-medium align-baseline border-0 text-primary"
            >
              5. Revisions and Errata
            </h2>

            <p
              class="p-0 mx-0 mt-0 mb-6 text-white align-baseline border-0 "
            >
              The materials appearing on WoW Classic DB’s web site could include
              technical, typographical, or photographic errors. WoW Classic DB does not
              warrant that any of the materials on its web site are accurate, complete,
              or current. WoW Classic DB may make changes to the materials contained on
              its web site at any time without notice. WoW Classic DB does not,
              however, make any commitment to update the materials.
            </p>

            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-2xl font-medium align-baseline border-0 text-primary"
            >
              6. Links
            </h2>

            <p
              class="p-0 mx-0 mt-0 mb-6 text-white align-baseline border-0 "
            >
              WoW Classic DB has not reviewed all of the sites linked to its Internet
              web site and is not responsible for the contents of any such linked site.
              The inclusion of any link does not imply endorsement by WoW Classic DB of
              the site. Use of any such linked web site is at the user’s own risk.
            </p>

            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-2xl font-medium align-baseline border-0 text-primary"
            >
              7. Site Terms of Use Modifications
            </h2>

            <p
              class="p-0 mx-0 mt-0 mb-6 text-white align-baseline border-0 "
            >
              WoW Classic DB may revise these terms of use for its web site at any time
              without notice. By using this web site you are agreeing to be bound by the
              then current version of these Terms and Conditions of Use.
            </p>

            <h2
              class="clear-both p-0 mx-0 mt-0 mb-5 text-2xl font-medium align-baseline border-0 text-primary"
            >
              8. Governing Law
            </h2>

            <p
              class="p-0 mx-0 mt-0 mb-6 text-white align-baseline border-0 "
            >
              Any claim relating to WoW Classic DB’s web site shall be governed by the
              laws of the State of Texas without regard to its conflict of law
              provisions.
            </p>
          </div>
          <!-- .entry-content .clear -->
        </article>
      </div>
    </div>
    <div class="relative flex-1 hidden w-0 lg:block">
      <img
        class="sticky inset-0 object-cover w-full h-screen items"
        src="@/assets/img/backgrounds/ahnqiraj.jpg"
        alt=""
      >
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      baseUrl: process.env.VUE_APP_BASE_URL
    }
  },
  metaInfo () {
    return {
      title: 'Terms and Conditions',
      meta: [
        { property: 'og:title', vmid: 'og:title', content: 'Terms and Conditions' },
        { property: 'og:url', vmid: 'og:url', content: `${this.baseUrl}${this.$route.path}` }
      ]
    }
  }
}
</script>
